import React from 'react'
import {
  Typography,
  TableContainer,
  Paper,
  Box,
  Button,
} from '@material-ui/core'
import { MenuButton } from 'components'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'
import { useHistory } from 'react-router-dom'
import helpers from 'helpers'
import { routes } from 'Routes'

const LastAccessesGranted = ({ data }) => {
  const history = useHistory()

  return (
    <Paper>
      <Box>
        <TableContainer>
          <Table
            size="medium"
            emptyMessage="Nenhuma aplicação foi encontrada"
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h5">
                    Últimos acessos concedidos
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <MenuButton>
                    <Button
                      onClick={() => history.push(routes.app.accessesGranted)}
                    >
                      Ver todos
                    </Button>
                  </MenuButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((data) => (
                <TableRow key={data.company.id}>
                  <TableCell>
                    <Typography variant="body1">{data.company.name}</Typography>
                    <Typography color="textSecondary">
                      {helpers.formatters.date(data.createdAt)}{' '}
                      {helpers.formatters.hours(data.createdAt)}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  )
}

export default LastAccessesGranted
