import api from 'service/api'

const leaveningIdAPI = api.create('leaveningId')

const get = async ({ userId = '', ...params }) => {
  return await leaveningIdAPI.get(`/users/${userId}`, { params })
}

const create = async (data) => {
  return await leaveningIdAPI.post('/users', data)
}

const put = async ({ userId = '', ...data }) => {
  return await leaveningIdAPI.put(`/users/${userId}`, data)
}

const updatePassword = async ({ userId = '', ...data }) => {
  return await leaveningIdAPI.put(`/users/${userId}/update_password`, data)
}

const newPassword = async (data) => {
  return await leaveningIdAPI.put(`/users/password`, data)
}

const permissions = async () => {
  return await leaveningIdAPI.get('/user/permissions')
}

const me = async () => {
  return await leaveningIdAPI.get('/users/me')
}

const resetPassword = async (data) => {
  return await leaveningIdAPI.post('/users/reset_password', data)
}

const companies = async ({ userToken, ...params }) => {
  return await leaveningIdAPI.get(`/users/${userToken}/apps`, { params })
}

const users = {
  get,
  create,
  put,
  updatePassword,
  newPassword,
  permissions,
  me,
  resetPassword,
  companies,
}

export default users
