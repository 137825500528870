import capitalize from './capitalize'

const ResponseErrorFormatter = (object) => {
  if (object) {
    const arrayKeys = Object.keys(object)
    const formattedErrorMessages = []
    arrayKeys.forEach((key) => {
      formattedErrorMessages.push({
        keyError: key,
        message: capitalize(object[key].join(', ')),
      })
    })
    return formattedErrorMessages
  }
  return []
}
export default ResponseErrorFormatter
