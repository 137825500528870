import moment from 'moment'
import 'moment/locale/pt-br'

moment.locale('pt-br')

const date = (date, show = false) => {
  if (!date) {
    return null
  }

  const dateMoment = moment(date)
  if (show) {
    dateMoment.subtract(1, 'day')
  }

  return dateMoment.utc().format('DD/MM/YYYY')
}

export default date
