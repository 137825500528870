import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Divider,
  Button,
} from '@material-ui/core'

const ConfirmationDeleteModal = ({
  open,
  title = '',
  suffixSentence = '',
  setOpen = () => {},
  handleRemove = () => {},
  objectToRemove = {},
  ...rest
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box>
          <Typography variant="h4">{title}</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box mt={1} mb={1}>
        <DialogContent>
          <Box>
            <Typography>
              Tem certeza que deseja remover {suffixSentence}?
            </Typography>
          </Box>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={() => handleRemove(objectToRemove)}
        >
          Remover
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDeleteModal
