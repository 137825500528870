import React from 'react'
import {
  Box,
  Grid,
  Typography,
  LinearProgress,
  makeStyles,
} from '@material-ui/core'
import { InfoCard } from 'components'
import theme from 'theme'
import style from './styles'
import useAuth from 'hooks/useAuth'
const useStyles = makeStyles(style)

const InfoBar = () => {
  const styles = useStyles()

  const auth = useAuth()

  return (
    <Box>
      <Grid
        container
        display="flex"
        alignItems="center"
        justify="space-between"
        spacing={2}
      >
        <Grid item xl={4} lg={4} sm={6} md={6} xs={12}>
          <InfoCard text="Olá" textColor={styles.textGrey}>
            <Typography variant="h4">{auth?.user?.name}</Typography>
          </InfoCard>
        </Grid>
        <Grid item xl={4} lg={4} sm={6} md={6} xs={12}>
          <InfoCard text="Cadastro Completo" textColor={styles.textGrey}>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="h6">100%</Typography>
              </Box>
              <Box width="100%" ml={1}>
                <LinearProgress variant="determinate" value={100} />
              </Box>
            </Box>
          </InfoCard>
        </Grid>
        <Grid item xl={4} lg={4} sm={6} md={6} xs={12}>
          <InfoCard
            text="Status da conta"
            textColor={styles.textWhite}
            backgroundColor={theme.palette.primary.main}
          >
            <Typography variant="h4" className={styles.textWhite}>
              {'Ativa'.toUpperCase()}
            </Typography>
          </InfoCard>
        </Grid>
      </Grid>
    </Box>
  )
}

export default InfoBar
