import React from 'react'

import { Grid, Card, CardContent, Divider, CardHeader } from '@material-ui/core'

import Form from './Form'

const ChangePassword = ({ className, ...rest }) => {
  return (
    <Grid className={className} container spacing={3} {...rest}>
      <Grid item xs={12}>
        <Card className={className} {...rest}>
          <CardHeader title="Alterar senha" />
          <Divider />
          <CardContent>
            <Form />
          </CardContent>
          <Divider />
        </Card>
      </Grid>
    </Grid>
  )
}

export default ChangePassword
