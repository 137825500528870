const statusChipColor = (confirmed, classes) => {
  if (confirmed) {
    return classes.confirmed
  }

  if (!confirmed) {
    return classes.notConfirmed
  }

  return null
}

export default statusChipColor
