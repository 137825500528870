import { useContext } from 'react'

import AuthContext from 'contexts/AuthContext'

const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthContext.Provider')
  }

  return {
    isLoading: context.isLoading,
    loaded: context.loaded,
    user: context.user,
    company: context.company,
    // permissions: context.permissions,
    menuItems: context.menuItems,
    documentationLinks: context.documentationLinks,
    loadData: context.loadData,
    loadDataWithAnimation: context.loadDataWithAnimation,
    actualStep: context.actualStep,
    setActualStep: context.setActualStep,
  }
}

export default useAuth
