import React from 'react'

import {
  Grid,
  TextField,
  Box,
  DialogContent,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'
import * as service from 'service'
import schema from './schema'
import helpers from 'helpers'

const AddressForm = ({
  userAddress = null,
  edit,
  children,
  submitSuccess = () => {},
  setLoading = () => {},
}) => {
  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      userAddressId: edit ? userAddress.id : '',
      addressName: edit ? userAddress.address?.addressName : '',
      number: edit ? userAddress.address?.number : '',
      zipCode: edit ? helpers.formatters.cep(userAddress.address?.zipCode) : '',
      neighbor: edit ? userAddress.address?.neighbor : '',
      city: edit ? userAddress.address?.city : '',
      state: edit ? userAddress.address?.state : '',
      country: edit ? userAddress.address?.country : '',
      mainAddress: edit ? userAddress.mainAddress : false,
    },
  })

  const snackbar = useSnackbar()

  const onSubmit = async (values) => {
    const submitMethod = edit
      ? service.leaveningId.addresses.edit
      : service.leaveningId.addresses.create

    try {
      setLoading(true)
      await submitMethod({ ...values })
      snackbar.open({
        message: `Endereço ${edit ? 'editado' : 'criado'} com sucesso!`,
        variant: 'success',
      })
      submitSuccess()
    } catch {
      snackbar.open({
        message: `Erro ao ${edit ? 'editar' : 'criar'} endereço`,
        variant: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Box my={1}>
          <Grid container spacing={2}>
            <Controller as={<></>} control={control} name="userAddressId" />
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Rua"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.addressName}
                    helperText={
                      errors && errors.addressName && errors.addressName.message
                    }
                    fullWidth
                  />
                }
                control={control}
                name="addressName"
                mode="onBlur"
              />
            </Grid>
            <Grid item md={7} sm={8} xs={12}>
              <Controller
                as={
                  <TextField
                    label="Bairro"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.neighbor}
                    helperText={
                      errors && errors.neighbor && errors.neighbor.message
                    }
                    fullWidth
                  />
                }
                control={control}
                name="neighbor"
                mode="onBlur"
              />
            </Grid>
            <Grid item md={2} sm={4} xs={12}>
              <Controller
                as={
                  <TextField
                    label="Número"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.number}
                    helperText={
                      errors && errors.number && errors.number.message
                    }
                    fullWidth
                  />
                }
                control={control}
                name="number"
                mode="onBlur"
              />
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Controller
                as={
                  <TextField
                    label="CEP"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.zipCode}
                    helperText={
                      errors && errors.zipCode && errors.zipCode.message
                    }
                    fullWidth
                  />
                }
                onChange={([e]) => {
                  return helpers.formatters.cep(e.target.value)
                }}
                control={control}
                name="zipCode"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Complemento"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.complements}
                    helperText={
                      errors && errors.complements && errors.complements.message
                    }
                    fullWidth
                  />
                }
                onChange={([e]) => {
                  return helpers.formatters.cep(e.target.value)
                }}
                control={control}
                name="complements"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Cidade"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.city}
                    helperText={errors && errors.city && errors.city.message}
                    fullWidth
                  />
                }
                control={control}
                name="city"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Estado"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.state}
                    helperText={errors && errors.state && errors.state.message}
                    fullWidth
                  />
                }
                control={control}
                name="state"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="País"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.country}
                    helperText={
                      errors && errors.country && errors.country.message
                    }
                    fullWidth
                  />
                }
                control={control}
                name="country"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Esse é meu endereço principal"
                  />
                }
                control={control}
                name="mainAddress"
                mode="onBlur"
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      {children}
    </form>
  )
}

export default AddressForm
