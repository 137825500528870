const styles = (theme) => ({
  textWhite: {
    color: '#FFFFFF',
  },
  textGrey: {
    color: theme.palette.text.secondary,
  },
})

export default styles
