import * as yup from 'yup'

const schema = yup.object().shape({
  currentPassword: yup.string().required('Senha atual é obrigatório').min(8),
  newPassword: yup
    .string()
    .required('Nova senha é obrigatório')
    .min(8)
    .matches(
      /(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[@#$%^&.+=\\'?("|\[\]\)|!,/{}`\-\´<>;:~]).{8,}$/,
      'Sua nova senha deve possuir letras, números e pelo menos um caractere especial',
    ),
  confirmPassword: yup
    .string()
    .required('Confirmar nova senha é obrigatório')
    .min(8)
    .oneOf([yup.ref('newPassword'), null], 'Senhas não coincidem.'),
})

export default schema
