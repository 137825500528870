import React from 'react'

import { Grid, TextField, Box, Button } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { LoadingButton } from 'components'
import helpers from 'helpers'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import useSnackbar from 'hooks/useSnackbar'
import * as service from 'service'
import schema from './schema'

const CompanyForm = ({ companyData = null }) => {
  const { control, handleSubmit, errors, setError } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: companyData?.data?.company?.name || '',
      tradeName: companyData?.data?.company?.tradeName || '',
      cnpj: helpers.formatters.cnpj(companyData?.data?.company?.cnpj || ''),
      token: companyData?.data?.company?.token || '',
    },
  })
  const history = useHistory()
  const snackbar = useSnackbar()

  const isEdit = () => {
    return companyData?.data ? true : false
  }

  const onSubmit = async (values) => {
    try {
      if (isEdit()) {
        values.cnpj = helpers.formatters.convertStringToInteger(values.cnpj)
        await service.leaveningId.companies.edit({
          token: values.token,
          company: values,
        })
        snackbar.open({
          message: `${`Empresa ${isEdit() ? 'Editar' : 'criar'} com sucesso!`}`,
          variant: 'success',
        })

        history.push(routes.app.company.main)
      } else {
        values.cnpj = helpers.formatters.convertStringToInteger(values.cnpj)
        await service.leaveningId.companies.create({
          params: { company: values },
        })
        snackbar.open({
          message: `${`Empresa ${isEdit() ? 'editar' : 'criar'} com sucesso!`}`,
          variant: 'success',
        })
      }
      history.push(routes.app.company.main)
    } catch (error) {
      const formattedErrors = helpers.formatters.responseErrorFormatter(
        error?.response?.data?.error?.errors,
      )

      if (!helpers.functions.isEmpty(formattedErrors)) {
        formattedErrors.forEach((error) => {
          setError(error.keyError, 'notEqual', error.message)
        })
      }
      snackbar.open({
        message: `${
          error?.response?.data?.error?.message ||
          `Ocorreu um erro ao ${isEdit() ? 'Editar' : 'criar'} empresa`
        }`,
        variant: 'error',
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Controller as={<></>} control={control} name="token" />
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Controller
            as={
              <TextField
                label="Razão social"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.name}
                helperText={errors && errors.name && errors.name.message}
                fullWidth
              />
            }
            control={control}
            name="name"
            mode="onBlur"
          />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Controller
            as={
              <TextField
                label="Nome Fantasia"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.tradeName}
                helperText={
                  errors && errors.tradeName && errors.tradeName.message
                }
                fullWidth
              />
            }
            control={control}
            name="tradeName"
            mode="onBlur"
          />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Controller
            as={
              <TextField
                label="CNPJ"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.cnpj}
                helperText={errors && errors.cnpj && errors.cnpj.message}
                fullWidth
              />
            }
            control={control}
            name="cnpj"
            mode="onBlur"
            onChange={([text]) => {
              return helpers.formatters.cnpj(text.target.value)
            }}
          />
        </Grid>
      </Grid>
      <Box mt={3} display="flex" justifyContent="flex-end" width="100%">
        <Box>
          <Button variant="contained" onClick={() => history.goBack()}>
            Voltar
          </Button>
        </Box>
        <Box ml={1}>
          <LoadingButton text="salvar" type="submit" />
        </Box>
      </Box>
    </form>
  )
}

export default CompanyForm
