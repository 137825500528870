import * as yup from 'yup'

export const schema = yup.object().shape({
  name: yup.string().max(255).required('Insira o nome.'),
  cpf: yup
    .string()
    .max(14)
    .matches(/^(\d{3}\.){2}\d{3}-\d{2}$/, {
      message: 'CPF inválido',
      excludeEmptyString: true,
    })
    .required('Insira o CPF.'),
  email: yup
    .string()
    .email('Email inválido.')
    .max(255)
    .required('Insira o email.'),
  birthDate: yup.string().required('Insira a data de aniversário'),
  phone: yup
    .string()
    .required('Insira o telefone')
    .matches(/\(?(\d{2})\) ?(\d{5})-(\d{4})/, {
      message: 'Telefone inválido',
      excludeEmptyString: true,
    }),
  policy: yup
    .boolean()
    .oneOf(
      [true],
      'É necessário aceitar os termos e condições para prosseguir.',
    ),
})

export default schema
