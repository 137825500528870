import React from 'react'
import PropTypes from 'prop-types'

import { Route } from 'react-router-dom'

import * as service from 'service'

const DefaultComponent = ({ children }) => <>{children}</>

const RouteWithLayout = ({
  auth,
  layout,
  provider,
  component: Component,
  permission,
  somePermissions,
  admin = false,
  redirect = true,
  ...rest
}) => {
  const Layout = layout || DefaultComponent
  const Provider = provider || DefaultComponent
  const isAuthenticated = service.leaveningId.auth.isAuthenticated()

  if (auth && !isAuthenticated) {
    service.leaveningId.auth.logout()
    return null
  }

  const renderRoute = (matchProps) => {
    return (
      <Layout
        permission={permission}
        somePermissions={somePermissions}
        admin={admin}
      >
        <Provider component={Provider}>
          <Component {...matchProps} />
        </Provider>
      </Layout>
    )
  }

  return <Route {...rest} render={(matchProps) => renderRoute(matchProps)} />
}

RouteWithLayout.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  path: PropTypes.string,
  permission: PropTypes.string,
  admin: PropTypes.bool,
}

export default RouteWithLayout
