import React, { useEffect, useState } from 'react'
import { Page, ContentHeader } from 'components/'
import { Container, Box, CircularProgress, Grid } from '@material-ui/core'
import { InfoBar, LastAccessesGranted } from './components'

import useAuth from 'hooks/useAuth'

import * as service from 'service'

export default function Home() {
  const auth = useAuth()

  const [response, setResponse] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getCompanies = async () => {
      setLoading(true)
      const response = await service.leaveningId.users.companies({
        userToken: auth?.user?.token,
        perPage: 5,
      })
      setResponse(response)
      setLoading(false)
    }
    getCompanies()
    //eslint-disable-next-line
  }, [])
  return (
    <Page title="OneID - Início">
      <Container>
        <ContentHeader menu="OneID" title="Home" subtitle="Início" />
        {loading ? (
          <Box
            id="loadBox"
            display="flex"
            width="100%"
            minHeight="700px"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box mt={5}>
              <InfoBar />
            </Box>
            <Grid>
              <Box mt={5}>
                <LastAccessesGranted data={response?.data?.companyUsers} />
              </Box>
            </Grid>
          </>
        )}
      </Container>
    </Page>
  )
}
