import React from 'react'

import { Box, Grid, Button } from '@material-ui/core'
import { LabelView, ButtonBox } from 'components'

import useAuth from 'hooks/useAuth'
import helpers from 'helpers'

const ViewUserData = ({ handleEdit }) => {
  const { user } = useAuth()
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <LabelView label="Nome" value={user?.name} />
        </Grid>
        <Grid item sm={6}>
          <LabelView label="CPF" value={helpers.formatters.cpf(user?.cpf)} />
        </Grid>
      </Grid>
      <LabelView label="Email" value={user?.email} />
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <LabelView
            label="Data de nascimento"
            value={helpers.formatters.date(user?.birthDate, true)}
          />
        </Grid>
        <Grid item sm={6}>
          <LabelView
            label="Telefone"
            value={helpers.formatters.phone(user?.phone)}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <ButtonBox>
          <Button color="primary" onClick={handleEdit} variant="contained">
            Editar
          </Button>
        </ButtonBox>
      </Box>
    </Box>
  )
}

export default ViewUserData
