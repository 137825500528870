import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import io from 'socket.io-client'
import helpers from 'helpers'
import * as service from 'service'
import { getBaseURL } from 'service/env'

import { useForm, Controller } from 'react-hook-form'
import { DatePicker } from '@material-ui/pickers'

import { useHistory, useLocation } from 'react-router-dom'
import {
  Box,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
  Link,
  makeStyles,
  Grid,
} from '@material-ui/core'

import { LoadingButton, ButtonBox } from 'components'
import { routes } from 'Routes'
import qs from 'query-string'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'
import useLoginWith from 'hooks/useLoginWith'

import schema from './schema'

const useStyles = makeStyles(() => ({
  root: {},
}))

const EditUserForm = ({ className, userData, mode, setEdit = () => {} }) => {
  const classes = useStyles()
  const history = useHistory()
  const { loadData } = useAuth()
  const { urlLoginWith } = useLoginWith()
  const socket = io(getBaseURL('socketLoginWithOneID'))
  const location = useLocation()
  const search = qs.parse(location.search)

  const { handleSubmit, errors, control, setError } = useForm({
    defaultValues: {
      name: userData?.name || '',
      cpf: helpers.formatters.cpf(userData?.cpf) || '',
      email: userData?.email || '',
      birthDate: userData?.birthDate || '',
      phone: helpers.formatters.phone(userData?.phone) || '',
      policy: userData ? true : false,
    },
    validationSchema: schema,
  })

  const snackbar = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)

  const isEdit = () => {
    return mode === 'edit'
  }

  const onSubmit = async (values) => {
    setIsLoading(true)
    const { name, phone, email } = values
    try {
      const cpf = helpers.formatters.convertStringToInteger(values.cpf)
      const birthDate = helpers.formatters.date(values.birthDate)
      if (isEdit()) {
        await service.leaveningId.users.put({
          userId: userData.token,
          user: {
            name,
            email,
            cpf,
            birthDate,
            phone,
          },
        })
        snackbar.open({
          message: 'Usuário atualizado com sucesso.',
          variant: 'success',
        })
        await loadData()

        setEdit(false)
      } else {
        setIsLoading(true)
        await service.leaveningId.users.create({
          user: {
            name,
            email,
            cpf,
            birthDate,
            phone,
          },
        })
        const firstName = name.substr(0, name.indexOf(' '))

        if (search.company_token && search.id) {
          socket.emit('register', {
            message: `Parabéns ${firstName}! Sua conta no OneID foi criada com sucesso! Em poucos minutos você receberá um e-mail para realizar o cadastro de sua senha.`,
            status: 'success',
            id: search.id,
          })
        } else {
          setIsLoading(false)

          history.push(urlLoginWith || routes.login)

          snackbar.open({
            autoHideDuration: 15000,
            vertical: 'top',
            horizontal: 'center',
            message: `Parabéns ${firstName}! Sua conta no OneID foi criada com sucesso! Em poucos minutos você receberá um e-mail para realizar o cadastro de sua senha.`,
            variant: 'success',
          })
        }
      }
    } catch (err) {
      setIsLoading(false)
      const formattedErrors = helpers.formatters.responseErrorFormatter(
        err?.response?.data?.error?.errors,
      )

      if (!helpers.functions.isEmpty(formattedErrors)) {
        formattedErrors.forEach((error) => {
          setError(error.keyError, 'notEqual', error.message)
        })
      }

      snackbar.open({
        autoHideDuration: 15000,
        vertical: 'top',
        horizontal: 'center',
        message: `${
          err?.response?.data?.error?.message ||
          `Erro ao ${isEdit() ? 'Editar' : 'criar'} usuário.`
        }`,
        variant: 'error',
      })
    }
  }

  return (
    <form
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors?.name}
                helperText={errors?.name?.message}
                type="name"
                label="Nome"
                margin="normal"
                variant="outlined"
              />
            }
            control={control}
            name="name"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            as={
              <TextField
                error={!!errors?.cpf}
                fullWidth
                helperText={errors?.cpf?.message}
                label="CPF"
                margin="normal"
                type="cpf"
                variant="outlined"
              />
            }
            control={control}
            onChange={([e]) => {
              return helpers.formatters.cpf(e.target.value)
            }}
            name="cpf"
            mode="onBlur"
          />
        </Grid>
      </Grid>
      <Controller
        as={
          isEdit() ? (
            <></>
          ) : (
            <TextField
              error={!!errors?.email}
              fullWidth
              helperText={errors?.email?.message}
              label="Email"
              margin="normal"
              type="email"
              variant="outlined"
            />
          )
        }
        control={control}
        name="email"
        mode="onBlur"
      />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Controller
            as={
              <DatePicker
                fullWidth
                format="DD/MM/yyyy"
                label="Data de nascimento"
                helperText={errors?.birthDate?.message}
                error={!!errors.birthDate}
                inputVariant="outlined"
                onChange={(newValue) => ({ value: newValue })}
                disableFuture
                margin="normal"
              />
            }
            control={control}
            name="birthDate"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            as={
              <TextField
                error={!!errors?.phone}
                fullWidth
                helperText={errors?.phone?.message}
                label="Telefone"
                margin="normal"
                type="text"
                variant="outlined"
              />
            }
            control={control}
            onChange={([e]) => {
              return helpers.formatters.phone(e.target.value)
            }}
            name="phone"
            mode="onBlur"
          />
        </Grid>
      </Grid>
      {!isEdit() && (
        <Box alignItems="center" display="flex" mt={2} ml={-1}>
          <Controller
            as={<Checkbox name="policy" />}
            control={control}
            name="policy"
          />
          <Typography variant="body2" color="textSecondary">
            Li e aceito os{' '}
            <Link component="a" href="#" color="secondary">
              Termos e condições
            </Link>
          </Typography>
          {!!errors?.policy && (
            <FormHelperText error>{errors?.policy?.message}</FormHelperText>
          )}
        </Box>
      )}
      <Box mt={2}>
        {isEdit() ? (
          <ButtonBox>
            <LoadingButton loading={isLoading} text="Salvar" />
          </ButtonBox>
        ) : (
          <LoadingButton loading={isLoading} text="Cadastrar" />
        )}
      </Box>
    </form>
  )
}

EditUserForm.propTypes = {
  className: PropTypes.string,
}

export default EditUserForm
