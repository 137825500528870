import React from 'react'
import { useLocation, Router } from 'react-router-dom'

import { ThemeProvider } from '@material-ui/styles'
import { withStyles } from '@material-ui/styles'

import 'configs/yup/locale'

import theme from 'theme'
import 'react-perfect-scrollbar/dist/css/styles.css'

import AuthProvider from 'providers/AuthProvider'
import LoginWithProvider from 'providers/LoginWithProvider'

import Routes from './Routes'
import history from 'service/history'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'

import 'react-perfect-scrollbar/dist/css/styles.css'

const App = () => (
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
      <LoginWithProvider>
        <Router history={history}>
          <ScrollToTop />
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </Router>
      </LoginWithProvider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>
)

const styles = {
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      height: '100%',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
    },
    a: {
      textDecoration: 'none',
    },
    '#root': {
      height: '100%',
    },
  },
}

function ScrollToTop() {
  const { pathname } = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default withStyles(styles)(App)
