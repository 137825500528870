import React, { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Grid,
  Card,
  CardHeader,
  Divider,
  Typography,
  CardContent,
  makeStyles,
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

import EmailsTable from './EmailsTable'
import EmailFormModal from './EmailFormModal'

import * as service from 'service'

import styles from './styles'

const useStyles = makeStyles(styles)

function EmailMain({ ...rest }) {
  const [params, setParams] = useState({})
  const [emails, setEmails] = useState([])
  const [email, setEmail] = useState()
  const [loading, setLoading] = useState(false)
  const [formOpen, setFormOpen] = useState(false)

  useEffect(() => {
    const loadEmails = async () => {
      if (params?.perPage) {
        setLoading(true)
        const response = await service.leaveningId.emails.get(params)
        setEmails(response?.data)
        setLoading(false)
      }
    }
    loadEmails()
  }, [params])

  const classes = useStyles()

  const handleNew = () => {
    setEmail()
    setFormOpen(true)
  }

  return (
    <Grid container spacing={3} {...rest}>
      <Grid item xs={12}>
        <Card {...rest}>
          <CardHeader
            title={
              <Box
                display="flex"
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="inherit">E-mails</Typography>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleNew}
                >
                  Cadastrar e-mail
                </Button>
              </Box>
            }
          />
          <Divider />
          <CardContent className={classes.cardContent}>
            <EmailsTable
              params={params}
              emails={emails?.emails || []}
              email={email}
              meta={emails?.meta || {}}
              loading={loading}
              setOpen={setFormOpen}
              setEmail={setEmail}
              setParams={setParams}
            />
          </CardContent>
          <EmailFormModal
            open={formOpen}
            email={email}
            loading={loading}
            params={params}
            setOpen={setFormOpen}
            setLoading={setLoading}
            setParams={setParams}
          />
        </Card>
      </Grid>
    </Grid>
  )
}

export default EmailMain
