import React from 'react'
import { useHistory, useLocation, Link as RouterLink } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Button,
  TextField,
  makeStyles,
  Link,
  Hidden,
} from '@material-ui/core'

import helpers from 'helpers'

import { routes } from 'Routes'
import * as service from 'service'
import schema from './schema'
import useSnackbar from 'hooks/useSnackbar'
import styles from './styles'

const useStyles = makeStyles(styles)

export const Form = () => {
  const history = useHistory()
  const snackbar = useSnackbar()
  const classes = useStyles(useStyles)
  const companyToken = new URLSearchParams(useLocation().search).get(
    'company_token',
  )
  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      email: '',
      password: '',
      companyToken: companyToken,
    },
  })

  const onSubmit = async (data) => {
    try {
      const payload = {
        ...data,
        device: helpers.functions.getCurrentDevice(),
      }

      const response = await service.leaveningId.auth.authenticationWith(
        payload,
      )
      localStorage.setItem(
        '@leavening-id/external-user-token',
        response.data.authToken,
      )
      history.push(routes.acceptData)
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        snackbar.open({
          message: 'Email ou senha inválidos',
          variant: 'error',
        })
        return
      }
      snackbar.open({
        message: 'Ocorreu algum erro! Tente novamente!',
        variant: 'error',
      })
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller as={<></>} control={control} name="companyToken" />
        <Controller
          as={
            <TextField
              label="Email"
              type="email"
              color="primary"
              variant="outlined"
              error={!!errors.email}
              helperText={errors && errors.email && errors.email.message}
              fullWidth
            />
          }
          control={control}
          name="email"
          mode="onBlur"
        />
        <Controller
          as={
            <TextField
              label="Senha"
              type="password"
              variant="outlined"
              margin="normal"
              error={!!errors.password}
              helperText={errors && errors.password && errors.password.message}
              fullWidth
            />
          }
          control={control}
          name="password"
          mode="onBlur"
        />
        <Box
          mt={2}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Button
            color="primary"
            className={classes.buttonHover}
            type="submit"
            variant="contained"
          >
            Entrar
          </Button>
          <Hidden smDown>
            <Link
              component={RouterLink}
              to="/forgot"
              variant="body2"
              color="textSecondary"
            >
              Esqueceu sua senha?
            </Link>
          </Hidden>
        </Box>
        <Hidden mdUp>
          <Box mt={2}>
            <Link
              component={RouterLink}
              to="/forgot"
              variant="body2"
              color="textSecondary"
            >
              Esqueceu sua senha?
            </Link>
          </Box>
        </Hidden>
      </form>
    </>
  )
}

export default Form
