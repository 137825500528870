import React, { useEffect } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import {
  Avatar,
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Link,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons'
import Page from '../../components/Page'
import Form from './components/Form'
import useSnackbar from 'hooks/useSnackbar'
import styles from './styles'

const useStyles = makeStyles(styles)

function Login() {
  const snackbar = useSnackbar()
  const classes = useStyles()
  const history = useHistory()

  const locationState = history.location.state

  const message = locationState
    ? locationState.error || locationState.success
    : ''

  useEffect(() => {
    if (locationState) {
      if (locationState.error) {
        snackbar.open({
          autoHideDuration: 15000,
          vertical: 'top',
          horizontal: 'center',
          message: message,
          variant: 'error',
        })
      } else {
        snackbar.open({
          autoHideDuration: 15000,
          vertical: 'top',
          horizontal: 'center',
          message: message,
          variant: 'success',
        })
      }
      history.replace()
    }
    // eslint-disable-next-line
  }, [message, locationState])

  return (
    <Page className={classes.root} title="Entrar">
      <Container maxWidth="md">
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <LockOutlinedIcon fontSize="large" />
            </Avatar>
            <Typography variant="h3" color="textPrimary">
              OneID
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Acesse sua conta
            </Typography>
            <Box mt={3}>
              <Form />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
            <Typography variant="body2" color="textSecondary">
              Não tem uma conta?{' '}
              <Link component={RouterLink} to="/register" color="primary">
                Registrar-se
              </Link>
            </Typography>
          </CardContent>
          <CardMedia
            className={classes.media}
            image="/images/card-media-login.jpg"
            title="Cover"
          >
            {/* <Typography color="inherit" variant="subtitle1">
              Alguma frase ou comentário de alguma pessoa
            </Typography>
            <Box alignItems="center" display="flex" mt={3}>
              <Avatar
                alt="Person"
                src="/static/register/how-it-works-mentorship.jpg"
              />
              <Box ml={3}>
                <Typography color="inherit" variant="body1">
                  Nome
                </Typography>
                <Typography color="inherit" variant="body2">
                  Cargo
                </Typography>
              </Box>
            </Box> */}
          </CardMedia>
        </Card>
      </Container>
    </Page>
  )
}

export default Login
