const convertStringToPhone = (phone) => {
  if (phone === null || phone === undefined) return ''

  phone = phone.replace(/\D/g, '')

  let l = phone.length

  l = phone.length

  if (l <= 2) return phone.replace(/^(\d{1,2}).*/, '($1')
  if (l <= 7 && l >= 3) return phone.replace(/^(\d{1,2})(\d{1,5}).*/, '($1) $2')

  return phone.replace(/^(\d{1,2})(\d{1,5})(\d{1,4}).*/, '($1) $2-$3')
}

export default convertStringToPhone
