import React, { useState } from 'react'

import LoginWithContext from 'contexts/LoginWithContext'

const LoginWithProvider = ({ children }) => {
  const [urlLoginWith, setUrlLoginWith] = useState('')

  const loadData = async (formattedUrlLoginWith) => {
    setUrlLoginWith(formattedUrlLoginWith)
  }

  return (
    <LoginWithContext.Provider
      value={{ urlLoginWith, setUrlLoginWith, loadData }}
    >
      {children}
    </LoginWithContext.Provider>
  )
}

export default LoginWithProvider
