import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  makeStyles,
  Grid,
} from '@material-ui/core'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import Page from '../../components/Page'
import EditUserForm from 'components/EditUserForm'
import styles from './styles'
import qs from 'query-string'
import useLoginWith from 'hooks/useLoginWith'

const useStyles = makeStyles(styles)

function RegisterView() {
  const classes = useStyles()
  const { urlLoginWith } = useLoginWith()

  const location = useLocation()
  const search = qs.parse(location.search)

  return (
    <Page className={classes.root} title="Register">
      <Container>
        <Card className={classes.card}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <CardContent className={classes.cardContent}>
                <LockOpenIcon className={classes.lockIcon} color="primary" />
                <Typography
                  gutterBottom
                  variant="h2"
                  color="textPrimary"
                  className={classes.titleSignUp}
                >
                  Cadastre-se no OneID
                </Typography>
                <Typography variant="subtitle1">
                  Cadastre-se na plataforma interna.
                </Typography>
                <Box mt={3}>
                  <Divider />
                  <EditUserForm />
                </Box>
                <Box my={2}>
                  <Divider />
                </Box>
                {!search.id && !search.company_token && (
                  <Link
                    component={RouterLink}
                    to={urlLoginWith || '/'}
                    variant="body2"
                    color="textSecondary"
                  >
                    Já possui uma conta?
                  </Link>
                )}
              </CardContent>
            </Grid>
            <Grid item md={6}>
              <Box height="100%" display={{ xs: 'none', md: 'flex' }}>
                <Container className={classes.bannerRight}>
                  <Grid item className={classes.bannerFirstRow}></Grid>
                </Container>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  )
}

export default RegisterView
