import React from 'react'
import { include } from 'named-urls'

import { Switch, Redirect, Route } from 'react-router-dom'
import { RouteWithLayout } from 'components'
import { Auth as AuthLayout, MainLayout } from 'layouts'

import Register from 'views/Register'
import LoginWith from 'views/LoginWith'
import AcceptData from 'views/AcceptData'
import Home from 'views/Home'
import Login from 'views/Login'
import Password from 'views/Password'
import { AccountView, ConfirmEmail } from 'views/AccountView'
import Security from 'views/Security'
import NotFound from 'views/NotFound'
import Forbidden from 'views/Forbidden'
import NewPassword from 'views/NewPassword'
import ForgotPassword from 'views/ForgotPassword'
import Dashboard from 'views/AccessGranted'
import { CompanyList, CompanyEdit, CompanyNew } from 'views/Company'
import SnackbarProvider from 'providers/SnackbarProvider'

import useLoginWith from 'hooks/useLoginWith'

const Routes = () => {
  const { urlLoginWith } = useLoginWith()
  return (
    <Switch>
      <RouteWithLayout
        path={urlLoginWith || routes.login}
        component={Login}
        provider={SnackbarProvider}
        exact
      />
      <RouteWithLayout
        path={routes.register.main}
        component={Register}
        provider={SnackbarProvider}
        exact
      />
      <RouteWithLayout
        path={routes.register.password}
        component={Password}
        provider={SnackbarProvider}
        exact
      />
      <RouteWithLayout
        path={routes.acceptData}
        component={AcceptData}
        provider={SnackbarProvider}
        exact
      />
      <RouteWithLayout
        path={routes.loginWithLeavening}
        component={LoginWith}
        provider={SnackbarProvider}
        exact
      />
      <RouteWithLayout
        path={routes.forgotPassword}
        component={ForgotPassword}
        layout={AuthLayout}
        redirect={false}
        exact
      />
      <RouteWithLayout
        path={routes.newPassword}
        component={NewPassword}
        layout={AuthLayout}
        redirect={false}
        exact
      />
      <RouteWithLayout
        path={routes.app.account}
        component={AccountView}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.app.confirmEmail}
        component={ConfirmEmail}
        layout={AuthLayout}
        redirect={false}
        exact
      />
      <RouteWithLayout
        path={routes.app.security}
        component={Security}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.app.root}
        component={Home}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.app.accessesGranted}
        component={Dashboard}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.app.company.main}
        component={CompanyList}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.app.company.edit}
        component={CompanyEdit}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.app.company.new}
        component={CompanyNew}
        layout={MainLayout}
        exact
        auth
      />
      <Route component={NotFound} exact path={routes.notFound} />
      <Route component={Forbidden} exact path={routes.forbidden} />
      <Redirect to={routes.notFound} />
    </Switch>
  )
}
export const routes = {
  app: include('', {
    root: '',

    accessesGranted: '/accesses-granted',

    account: '/account',
    confirmEmail: '/confirm-email/:confirmation_token',

    company: include('/company', {
      main: '',
      new: 'new',
      edit: ':token/edit',
    }),

    security: '/security',
  }),

  forgotPassword: '/forgot',

  login: '/signin',

  newPassword: '/new-password/:reset_password_token?',

  register: include('/register', {
    main: '',
    password: 'password',
  }),

  loginWithLeavening: '/auth_provider',

  acceptData: '/accept_data',

  notFound: '/not-found',
  forbidden: '/forbidden',

  resume: '/resume',
}

export default Routes
