import api from 'service/api'
import humps from 'humps'
import { routes } from 'Routes'
import history from 'service/history'
import { AUTH_TOKEN } from 'service/constants'

const leaveningIdAPI = api.create('leaveningId', false)

const login = async (data) => {
  const response = await leaveningIdAPI.post('/authentication', data)
  const token = response.data.authToken

  setToken(token)

  return response
}

const forgotPassword = async (data) => {
  const response = await leaveningIdAPI.post('/users/password', data)

  return response
}

const authenticationWith = async (data) => {
  return await leaveningIdAPI.post('/authentication_with', data)
}

const setToken = (token) => {
  localStorage.setItem(AUTH_TOKEN, token)
}

const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN)
}

const logout = () => {
  localStorage.removeItem(AUTH_TOKEN)
  history.push(routes.login)
}

const getUserIdFromToken = () => {
  const token = getToken()
  const payload = humps.camelizeKeys(JSON.parse(atob(token.split('.')[1])))

  return payload.userId
}

const isAuthenticated = () => {
  const token = getToken()
  return !!token
}

const auth = {
  login,
  forgotPassword,
  logout,
  getToken,
  setToken,
  isAuthenticated,
  getUserIdFromToken,
  authenticationWith,
}

export default auth
