import React from 'react'

import { Container, Box, Card, CardContent } from '@material-ui/core'

import { ContentHeader, Page } from 'components'
import { CompanyForm } from './components'

const CompanyNew = () => {
  return (
    <Page title="Editar empresa">
      <Container>
        <ContentHeader
          menu="Empresas"
          title="Criação"
          subtitle="Nova empresa"
        />
        <Box mt={3}>
          <Card>
            <CardContent>
              <CompanyForm />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  )
}

export default CompanyNew
