import React from 'react'
import { Paper, makeStyles, Box, Typography } from '@material-ui/core'
import styles from './styles'

const useStyles = makeStyles(styles)

const InfoCard = ({
  text,
  children,
  textColor = 'textSecondary',
  backgroundColor = '#fff',
}) => {
  const styles = useStyles()

  return (
    <Paper className={styles.card}>
      <Box p={2} bgcolor={backgroundColor}>
        <Box>
          <Typography variant="h6" className={textColor}>
            {text.toUpperCase()}
          </Typography>
        </Box>
        <Box pt={2}>{children}</Box>
      </Box>
    </Paper>
  )
}

export default InfoCard
