import React from 'react'
import { Typography, TableContainer, Box } from '@material-ui/core'
import helpers from 'helpers/'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

const AppsTable = ({ companies }) => {
  return (
    <Box>
      <TableContainer>
        <Table
          size="medium"
          emptyMessage="Nenhuma aplicação foi encontrada"
          noWrap
        >
          <TableHead>
            <TableRow>
              <TableCell width={'50%'}>Nome da aplicação</TableCell>
              <TableCell width={'50%'}>Ativado em</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((data) => (
              <TableRow key={data.company.token}>
                <TableCell>
                  <Typography>{data.company.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {helpers.formatters.date(data.createdAt)}{' '}
                    {helpers.formatters.hours(data.createdAt)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default AppsTable
