const getCurrentDevice = () => {
  const device = {
    appCodeName: navigator.appCodeName,
    platform: navigator.platform,
    appVersion: navigator.appVersion,
  }
  return device
}

export default getCurrentDevice
