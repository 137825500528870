import React, { useState } from 'react'

import { useForm, Controller } from 'react-hook-form'

import { Box, TextField, Grid } from '@material-ui/core'

import useSnackbar from 'hooks/useSnackbar'
import useAuth from 'hooks/useAuth'

import { LoadingButton } from 'components'

import * as service from 'service'

import schema from './schema'

const FormPassword = ({ className }) => {
  const [isLoading, setIsLoading] = useState(false)
  const snackbar = useSnackbar()
  const { user } = useAuth()

  const { handleSubmit, errors, control, reset } = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    mode: 'onChange',
    validationSchema: schema,
  })

  const onSubmit = async (values) => {
    setIsLoading(true)
    try {
      await service.leaveningId.users.updatePassword({
        userId: user.token,
        ...values,
      })
      snackbar.open({
        message: 'Senha atualizada com sucesso.',
        variant: 'success',
      })
      reset()
      setIsLoading(false)
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.error?.message ||
          'Erro ao tentar cadastrar nova senha, verifique se a senha atual está correta e tente novamente',
        variant: 'error',
      })
      setIsLoading(false)
    }
  }

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors?.currentPassword}
                helperText={errors?.currentPassword?.message}
                type="password"
                label="Senha atual"
                margin="normal"
                variant="outlined"
              />
            }
            control={control}
            name="currentPassword"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors?.newPassword}
                helperText={errors?.newPassword?.message}
                type="password"
                label="Nova senha"
                margin="normal"
                variant="outlined"
              />
            }
            control={control}
            name="newPassword"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors?.confirmPassword}
                helperText={errors?.confirmPassword?.message}
                type="password"
                label="Confirmar nova senha"
                margin="normal"
                variant="outlined"
              />
            }
            control={control}
            name="confirmPassword"
          />
        </Grid>
      </Grid>
      <Box mt={2} display="flex" width="100%" justifyContent="flex-end">
        <LoadingButton loading={isLoading} text="Salvar" />
      </Box>
    </form>
  )
}

export default FormPassword
