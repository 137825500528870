import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect, useLocation, matchPath } from 'react-router-dom'

import { makeStyles, Box, Fab, Tooltip } from '@material-ui/core'

import { TopBar, ScreenLoading, NavBar } from './components'

import styles from './styles'

import useAuth from 'hooks/useAuth'

import SnackbarProvider from 'providers/SnackbarProvider'

import { routes } from 'Routes'

import { Permitted } from 'components'

import { HelpCircle as HelpCircleIcon } from 'react-feather'

const useStyles = makeStyles(styles)

const Main = ({ permission, somePermissions, admin, children }) => {
  const classes = useStyles()
  const auth = useAuth()
  const location = useLocation()
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const [showHelpButton, setShowHelpButton] = useState(false)
  const [helpUrl, setHelpUrl] = useState()
  const documentationLinks = auth.documentationLinks

  useEffect(() => {
    const verifyMatch = (uri, url) => {
      if (!matchPath(location.pathname, { path: uri, exact: true })) {
        return
      }

      setShowHelpButton(true)
      return setHelpUrl(url)
    }

    const matchDocumentationLink = async () => {
      setShowHelpButton(false)
      documentationLinks &&
        documentationLinks.map((documentationLink) => {
          if (documentationLink.pageUri && documentationLink.url) {
            verifyMatch(documentationLink.pageUri, documentationLink.url)
          }

          return documentationLink
        })
    }

    matchDocumentationLink()

    // eslint-disable-next-line
  }, [documentationLinks, location.key])

  return (
    <ScreenLoading loaded={auth.loaded}>
      <SnackbarProvider>
        <Permitted
          someTags={somePermissions}
          tag={permission}
          admin={admin}
          fallback={<Redirect to={routes.forbidden} />}
        >
          <Box className={classes.root}>
            <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
            <NavBar
              onMobileClose={() => setMobileNavOpen(false)}
              openMobile={isMobileNavOpen}
            />
            <Box className={classes.wrapper}>
              <Box className={classes.contentContainer}>
                <Box className={classes.content}>
                  {children}
                  {showHelpButton && (
                    <Box display="flex" justifyContent="flex-end" mr={1}>
                      <Tooltip title="Acesso a documentação">
                        <Fab
                          color="secondary"
                          aria-label="add"
                          size="small"
                          onClick={() => window.open(helpUrl, '_blank')}
                          className={classes.floatingButton}
                        >
                          <HelpCircleIcon />
                        </Fab>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Permitted>
      </SnackbarProvider>
    </ScreenLoading>
  )
}

Main.propTypes = {
  children: PropTypes.any,
}

export default Main
