import React from 'react'
import { useHistory } from 'react-router-dom'
import { RHFInput } from 'react-hook-form-input'
import { useForm } from 'react-hook-form'
import useAuth from 'hooks/useAuth'
import { LoadingButton } from 'components'
import { Box, TextField } from '@material-ui/core'
import helpers from 'helpers'
import { routes } from 'Routes'
import * as service from 'service'
import schemas from '../../schemas'
import useSnackbar from 'hooks/useSnackbar'

export const Form = ({ defaultValues }) => {
  const history = useHistory()
  const auth = useAuth()
  const snackbar = useSnackbar()
  const { register, setValue, handleSubmit, errors } = useForm({
    validationSchema: schemas.newPassword,
    defaultValues: defaultValues,
  })

  const [loading, setLoading] = React.useState(false)

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const response = await service.leaveningId.users.newPassword(data)
      if (response?.error) {
        snackbar.open({
          message:
            response?.error?.message || 'Erro ao tentar cadastrar nova senha.',
          variant: 'error',
        })
        return null
      }
      const login = {
        email: response && response.data && response.data.email,
        password: data.password,
        device: helpers.functions.getCurrentDevice(),
      }
      await service.leaveningId.auth.login(login)
      auth.loadDataWithAnimation()
      snackbar.open({
        autoHideDuration: 15000,
        vertical: 'top',
        horizontal: 'center',
        message: response.data.message,
        variant: 'success',
      })
      history.push(routes.app.root)
    } catch (error) {
      snackbar.open({
        autoHideDuration: 15000,
        vertical: 'top',
        horizontal: 'center',
        message:
          error?.response?.data?.error?.message ||
          'Erro ao tentar cadastrar nova senha.',
        variant: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RHFInput
          as={
            <TextField
              label="Nova senha"
              type="password"
              color="primary"
              variant="outlined"
              error={!!errors.password}
              helperText={errors && errors.password && errors.password.message}
              fullWidth
            />
          }
          register={register}
          setValue={setValue}
          name="password"
          mode="onChange"
        />
        <RHFInput
          as={
            <TextField
              label="Confirme a senha"
              type="password"
              variant="outlined"
              margin="normal"
              error={!!errors.passwordConfirm}
              helperText={
                errors &&
                errors.passwordConfirm &&
                errors.passwordConfirm.message
              }
              fullWidth
            />
          }
          register={register}
          setValue={setValue}
          name="passwordConfirm"
          mode="onChange"
        />
        <RHFInput
          as={<></>}
          defaultValue={defaultValues.reset_password_token}
          register={register}
          name="resetPasswordToken"
        />

        <Box
          mt={2}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <LoadingButton text="Cadastrar nova senha" loading={loading} />
        </Box>
      </form>
    </>
  )
}

export default Form
