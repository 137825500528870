import React, { useState, useEffect } from 'react'

import {
  Container,
  Box,
  Button,
  TablePagination,
  CircularProgress,
  useMediaQuery,
  Paper,
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { useTheme } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { ContentHeader, Page } from 'components'
import { CompanyTable } from './components'
import { routes } from 'Routes'
import usePagination from 'hooks/usePagination'

import * as service from 'service'

const CompanyList = () => {
  const {
    perPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination(10)
  const theme = useTheme()
  const history = useHistory()
  const [response, setResponse] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  useEffect(() => {
    const loadData = async () => {
      const response = await service.leaveningId.companies.get({
        perPage,
        page,
      })
      setResponse(response)
      setIsLoading(false)
    }
    loadData()
    // eslint-disable-next-line
  }, [isLoading, perPage, page])

  return (
    <Page title="Empresas">
      <Container>
        <ContentHeader
          menu="Empresas"
          title="Listagem"
          subtitle="Todas as empresas"
        >
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => history.push(routes.app.company.new)}
          >
            Nova Empresa
          </Button>
        </ContentHeader>
        {!isLoading ? (
          <Box mt={3}>
            <Paper>
              <CompanyTable
                ownerUserCompanies={response?.data?.ownerUserCompanies || []}
                setIsLoading={setIsLoading}
              />
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount || 0}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Paper>
          </Box>
        ) : (
          <Box
            id="loadBox"
            display="flex"
            width="100%"
            minHeight="700px"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Page>
  )
}

export default CompanyList
