const styles = (theme) => ({
  h3: {
    fontWeight: 600,
  },
  title: {
    color: '#263238',
  },
  menu: {
    color: '#546E7A',
  },
})
export default styles
