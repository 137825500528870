import React from 'react'
import { Grid } from '@material-ui/core'
import AddressesMain from './AddressesMain'

function Address({ ...rest }) {
  return (
    <Grid container spacing={3} {...rest}>
      <Grid item xs={12}>
        <AddressesMain />
      </Grid>
    </Grid>
  )
}

export default Address
