import * as yup from 'yup'

export const schema = yup.object().shape({
  email: yup
    .string()
    .email('Email inválido')
    .max(255)
    .required('Email obrigatório'),
})

export default schema
