import useAuth from './useAuth'

const usePermissions = () => {
  const auth = useAuth()

  const isAdmin = () => auth.user.admin

  const permitted = true //(tag) => {
  //   if (auth.user.admin) {
  //     return true
  //   }

  //   const permission = auth.permissions.find(
  //     (permission) => permission.tag === tag,
  //   )

  //   if (!permission) {
  //     return true
  //   }

  //   return permission.status
  // }

  const some = (tags) => tags.some(permitted)

  return { permitted, some, isAdmin }
}

export default usePermissions
