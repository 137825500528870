const { withTheme } = require('@material-ui/styles')

const styles = (theme) => ({
  buttonHover: {
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: '#546E7A',
      color: withTheme,
    },
  },
})

export default styles
