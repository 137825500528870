import React from 'react'
import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  CircularProgress,
  // List,
  // ListItem,
  Typography,
  makeStyles,
} from '@material-ui/core'
import io from 'socket.io-client'
import Page from '../../components/Page'
import VerticalLogo from '../../components/VerticalLogo'
import { getBaseURL } from 'service/env'
import useFetch from 'hooks/useFetch'
import * as service from 'service'
import helpers from 'helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(3, 4, 4, 4),
  },
  boxLogo: {
    paddingBottom: 10,
  },
  logo: {
    display: 'flex',
    margin: '0 auto',
    height: 75,
  },
  buttonAccept: {
    display: 'flex',
    margin: 'auto',
    marginTop: 15,
    width: '100%',
  },
  listContainer: {
    marginTop: 15,
  },
}))

function AcceptData() {
  const classes = useStyles()

  const { response, isLoading } = useFetch(service.leaveningId.companies.get, {
    token: localStorage.getItem('@leavening-id/socket-client/company_token'),
  })

  const socket = io(getBaseURL('socketLoginWithOneID'))

  function handleSubmit() {
    socket.emit('login', {
      token: localStorage.getItem('@leavening-id/external-user-token'),
      id: localStorage.getItem('@leavening-id/socket-client'),
      companyToken: localStorage.getItem(
        '@leavening-id/socket-client/company_token',
      ),
      device: helpers.functions.getCurrentDevice(),
    })
    localStorage.removeItem('@leavening-id/external-user-token')
    localStorage.removeItem('@leavening-id/socket-client')
    localStorage.removeItem('@leavening-id/socket-client/company_token')
  }

  return (
    <Page className={classes.root} title="Entrar">
      {isLoading ? (
        <Box
          id="loadBox"
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container maxWidth="xs">
          <Card className={classes.card}>
            <CardContent className={classes.content}>
              <Box className={classes.boxLogo}>
                <VerticalLogo className={classes.logo} />
              </Box>
              <Box mt={1}>
                <Typography variant="subtitle1" color="textSecondary">
                  Para continuar, o OneID compartilhará com o app{' '}
                  <b>{response.data.company.tradeName}</b>.
                </Typography>
                {/* <List dense={true} className={classes.listContainer}>
                  {data.shareData.map((data, index) => (
                    <ListItem button key={index}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {data}
                      </Typography>
                    </ListItem>
                  ))}
                </List> */}
              </Box>

              <Button
                className={classes.buttonAccept}
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
              >
                Entrar
              </Button>
            </CardContent>
          </Card>
        </Container>
      )}
    </Page>
  )
}

export default AcceptData
