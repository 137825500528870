import api from 'service/api'

const leaveningIdAPI = api.create('leaveningId')

const get = async ({ userAddressId = '', ...params }) => {
  return await leaveningIdAPI.get(`/addresses/${userAddressId}`, { params })
}

const edit = async ({ userAddressId, ...address }) => {
  return await leaveningIdAPI.put(`/addresses/${userAddressId}`, {
    ...address,
  })
}

const create = async ({ ...params }) => {
  return await leaveningIdAPI.post('/addresses/', params)
}

const destroy = async ({ userAddressId }) => {
  return await leaveningIdAPI.delete(`/addresses/${userAddressId}`)
}

const changeMainAddress = async ({ userAddressId }) => {
  return await leaveningIdAPI.put(
    `/user_addresses/change_main_address/${userAddressId}`,
  )
}

const addresses = {
  get,
  edit,
  create,
  destroy,
  changeMainAddress,
}

export default addresses
