const statusLabel = (confirmed) => {
  if (confirmed) {
    return 'Confirmado'
  }

  if (!confirmed) {
    return 'Não confirmado'
  }

  return '-'
}

export default statusLabel
