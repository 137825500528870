export const getBaseURL = (project) => envsBaseUrl[project][env]

const env = process.env.REACT_APP_API || 'development'

const envsBaseUrl = {
  leaveningId: {
    development: 'http://localhost:3005',
    sandbox: 'https://api.sandbox.oneid.com.br',
    production: 'https://api.oneid.com.br',
  },
  socketLoginWithOneID: {
    development: 'http://172.18.0.9:5000',
    sandbox: 'https://auth.sandbox.oneid.com.br',
    production: 'https://auth.oneid.com.br',
  },
}
