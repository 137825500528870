import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { routes } from 'Routes'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import { Box, CircularProgress } from '@material-ui/core'

const ConfirmEmail = ({ match }) => {
  const confirmationToken = match.params.confirmation_token
  const isAuthenticated = service.leaveningId.auth.isAuthenticated()
  const history = useHistory()
  const snackbar = useSnackbar()
  const redirect = isAuthenticated ? routes.app.account : routes.app.root

  const redirectTo = () => {
    setTimeout(() => {
      history.replace(redirect, { tab: 'mail' })
    }, 2000)
  }

  useEffect(() => {
    const verifyToken = () => {
      if (!confirmationToken) {
        snackbar.open({
          message:
            'Ocorreu um erro ao confirmar seu e-mail. Certifique-se de estar usando a URL correta.',
          variant: 'error',
        })
        redirectTo()
      }
    }

    const confirmEmail = async () => {
      if (confirmationToken) {
        try {
          await service.leaveningId.emails.confirm({ confirmationToken })
          snackbar.open({
            message: 'Seu e-mail foi confirmado com sucesso',
            variant: 'success',
          })
          redirectTo()
        } catch {
          snackbar.open({
            message:
              'Ocorreu um erro ao confirmar seu e-mail. Certifique-se de estar usando a URL correta.',
            variant: 'error',
          })
          redirectTo()
        }
      }
    }
    verifyToken()
    confirmEmail()
    //eslint-disable-next-line
  }, [confirmationToken])

  return (
    <Box
      display="flex"
      width="100%"
      minHeight="700px"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size={60} />
    </Box>
  )
}

export default ConfirmEmail
