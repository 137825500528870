import { useContext } from 'react'

import LoginWithContext from 'contexts/LoginWithContext'

const useLoginWith = () => {
  const context = useContext(LoginWithContext)

  if (context === undefined) {
    throw new Error(
      'useLoginWith must be used within a LoginWithContext.Provider',
    )
  }

  return {
    urlLoginWith: context.urlLoginWith,
    setUrlLoginWith: context.setUrlLoginWith,
    loadData: context.loadData,
  }
}

export default useLoginWith
