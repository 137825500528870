import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Page } from 'components'
import {
  Container,
  Card,
  CardContent,
  Avatar,
  Typography,
  Box,
  Link,
  Divider,
  makeStyles,
} from '@material-ui/core'
import Form from './components/Form'
import LockIcon from '@material-ui/icons/Lock'
import styles from './styles'
import useLoginWith from 'hooks/useLoginWith'

const useStyles = makeStyles(styles)

const ForgotPassword = () => {
  const classes = useStyles()
  const { urlLoginWith } = useLoginWith()

  return (
    <Page className={classes.root} title="Esqueci minha senha">
      <Container maxWidth="md">
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <LockIcon fontSize="large" />
            </Avatar>
            <Typography variant="h3" color="textPrimary">
              Olá
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Digite seu e-mail para recuperar a senha
            </Typography>
            <Box mt={3}>
              <Form urlLoginWith={urlLoginWith} />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
            <Link
              component={RouterLink}
              to={urlLoginWith || '/'}
              variant="body2"
              color="textSecondary"
            >
              Voltar
            </Link>
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

export default ForgotPassword
