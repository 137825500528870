import moment from 'moment'
import 'moment/locale/pt-br'

moment.locale('pt-br')

const hours = (date) => {
  if (!date) {
    return null
  }

  return moment(date).utc().format('HH:mm')
}

export default hours
