import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  makeStyles,
} from '@material-ui/core'

import { ContentHeader, Page } from 'components'

import General from './General'
import Address from './Address'
import Email from './Email'

import helpers from 'helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

function AccountView() {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState('general')
  const [titleComplement, setTitleComplement] = useState('Geral')
  const history = useHistory()
  const location = useLocation()

  const tabs = [
    { value: 'general', label: 'Geral' },
    { value: 'mail', label: 'E-mails' },
    { value: 'address', label: 'Endereços' },
  ]

  useEffect(() => {
    const verifyLocationState = () => {
      if (location.state?.tab) {
        setCurrentTab(location.state.tab)
      }
    }

    const replaceLocation = () => {
      if (currentTab === 'general') {
        history.replace(location.pathname)
      } else {
        history.replace(location.pathname + '?page=1')
      }
    }
    verifyLocationState()
    replaceLocation()
    setTitleComplement(helpers.account.mountTitle(currentTab))
    //eslint-disable-next-line
  }, [currentTab])

  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  return (
    <Page
      className={classes.root}
      title={`Informações Pessoais ${titleComplement}`}
    >
      <Container maxWidth="lg">
        <ContentHeader
          menu="Configurações"
          title="Conta"
          subtitle="Informações pessoais"
        />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
            className={classes.tabs}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                onClick={(e) => handleTabsChange(e, tab.value)}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>{currentTab === 'general' && <General />}</Box>
        <Box mt={3}>{currentTab === 'mail' && <Email />}</Box>
        <Box mt={3}>{currentTab === 'address' && <Address />}</Box>
      </Container>
    </Page>
  )
}

export default AccountView
