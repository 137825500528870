import React from 'react'

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
  Divider,
  Button,
  Typography,
} from '@material-ui/core'

import { LoadingButton } from 'components'

import { ButtonBox } from 'components'

import AddressForm from './AddressForm'

const AddressFormModal = ({
  open,
  userAddress,
  loading,
  params,
  setParams = () => {},
  setLoading = () => {},
  setOpen = () => {},
}) => {
  const edit = userAddress ? true : false

  const submitSuccess = () => {
    setLoading(false)
    setOpen(false)
    setParams({ perPage: params?.perPage, page: params?.page })
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      disableBackdropClick
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box>
          <Typography variant="h4">{`${
            edit ? 'Editar ' : 'Cadastrar novo '
          } endereço`}</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <AddressForm
        edit={edit}
        userAddress={userAddress}
        setLoading={setLoading}
        submitSuccess={submitSuccess}
      >
        <Divider />
        <DialogActions p={2}>
          <ButtonBox width="100%" mr={2}>
            <Box mr={2}>
              <Button
                type="button"
                variant="outlined"
                onClick={() => setOpen(false)}
              >
                Cancelar
              </Button>
            </Box>
            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
              text="Salvar"
            />
          </ButtonBox>
        </DialogActions>
      </AddressForm>
    </Dialog>
  )
}

export default AddressFormModal
