import React from 'react'

import { Box } from '@material-ui/core'

const ButtonBox = ({ children, ...rest }) => {
  return (
    <Box display="flex" justifyContent="flex-end" {...rest}>
      {children}
    </Box>
  )
}

export default ButtonBox
