const mountTitle = (title) => {
  switch (title) {
    case 'general':
      return '- Geral'
    case 'mail':
      return '- E-mails'
    case 'address':
      return '- Endereços'
    default:
      return ''
  }
}

export default mountTitle
