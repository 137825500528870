import React from 'react'
import { makeStyles, Typography, Box } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  rootLogo: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  textLogo: {
    color: theme.palette.primary.main,
    // fontFamily: '"DDCHardware"',
    fontSize: '1.4rem',
  },
}))

function VerticalLogo(props) {
  const classes = useStyles()
  return (
    <Box className={classes.rootLogo}>
      <img
        alt="VerticalLogo"
        src="/static/logo.png"
        className={classes.imageLogo}
        {...props}
      />
      <Typography variant="h3" className={classes.textLogo}>
        OneID
      </Typography>
    </Box>
  )
}

export default VerticalLogo
