import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Typography,
  TableContainer,
  Button,
  TablePagination,
  useMediaQuery,
  Box,
  Chip,
  makeStyles,
} from '@material-ui/core'

import { ConfirmationDeleteModal, MenuButton } from 'components'

import { useTheme } from '@material-ui/styles'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

import styles from './styles'

import helpers from 'helpers'

import usePagination from 'hooks/usePagination'
import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

const useStyles = makeStyles(styles)

const EmailRow = ({
  email,
  setConfirmOpen = () => {},
  setOpen = () => {},
  setEmail = () => {},
}) => {
  const snackbar = useSnackbar()

  const handleEdit = () => {
    setEmail(email)
    setOpen(true)
  }

  const handleDestroy = () => {
    setEmail(email)
    setConfirmOpen(true)
  }

  const resendConfirmation = async () => {
    try {
      await service.leaveningId.emails.resendConfirmation({ emailId: email.id })
      snackbar.open({
        message: 'Novo e-mail de confirmação enviado',
        variant: 'success',
      })
    } catch (err) {
      snackbar.open({
        message:
          err?.response?.data?.error?.message ||
          'Ocorreu um erro ao reenviar e-mail de confirmação',
        variant: 'error',
      })
    }
  }

  const classes = useStyles()

  return (
    <TableRow>
      <TableCell>
        <Typography>{email.email || '-'}</Typography>
      </TableCell>
      <TableCell>
        <Chip
          label={helpers.email.statusLabel(email.confirmed)}
          icon={helpers.email.statusIcon(email.confirmed)}
          className={helpers.email.statusChipColor(email.confirmed, classes)}
        />
      </TableCell>
      <TableCell>
        <Typography>{email.primary ? 'Sim' : 'Não'}</Typography>
      </TableCell>
      <TableCell align="right">
        {!email.primary && (
          <MenuButton>
            <Button onClick={handleEdit}>Editar</Button>
            {!email.confirmed && (
              <Button onClick={resendConfirmation}>
                Reenviar e-mail de confirmação
              </Button>
            )}
            <Button onClick={handleDestroy}>Remover</Button>
          </MenuButton>
        )}
      </TableCell>
    </TableRow>
  )
}

const EmailsTable = ({
  emails,
  meta,
  params,
  email,
  isLoading = false,
  setOpen = () => {},
  setEmail = () => {},
  setParams = () => {},
}) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

  const {
    perPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    onRemoveLastFromPage,
  } = usePagination(5)

  const snackbar = useSnackbar()
  useEffect(() => {
    setParams({ perPage, page })
    //eslint-disable-next-line
  }, [perPage, page])

  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const handleDestroy = async (emailId) => {
    try {
      await service.leaveningId.emails.destroy({ emailId })
      snackbar.open({
        message: 'E-mail removido com sucesso',
        variant: 'success',
      })
      setConfirmDeleteOpen(false)
      if (page > 1) {
        onRemoveLastFromPage(meta?.totalCount - 1)
      }
      setParams({
        perPage,
        page,
      })
    } catch (err) {
      snackbar.open({
        message: 'Ocorreu um erro ao remover esse e-mail',
        variant: 'error',
      })
    }
  }

  return (
    <Box>
      <PerfectScrollbar>
        <TableContainer>
          <Table
            size="medium"
            emptyMessage="Nenhum e-mail foi encontrado"
            noWrap
            isLoading={isLoading}
          >
            <TableHead>
              <TableRow>
                <TableCell width="60%">E-mail</TableCell>
                <TableCell width="20%">Confirmado</TableCell>
                <TableCell width="10%">Principal</TableCell>
                <TableCell width="10%" align="right">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emails.map((email) => (
                <EmailRow
                  key={email?.id}
                  email={email}
                  setEmail={setEmail}
                  setOpen={setOpen}
                  setConfirmOpen={setConfirmDeleteOpen}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={meta?.totalCount || 0}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={params?.page - 1}
        rowsPerPage={perPage}
        rowsPerPageOptions={[5, 10, 15, 25]}
        labelRowsPerPage={isDesktop ? 'Por página' : ''}
        nextIconButtonProps={{ size: 'small' }}
        backIconButtonProps={{ size: 'small' }}
      />

      <ConfirmationDeleteModal
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        suffixSentence="este e-mail"
        title="Remover e-mail"
        handleRemove={handleDestroy}
        objectToRemove={email?.id}
      />
    </Box>
  )
}

export default EmailsTable
