import React, { useState } from 'react'

import { RHFInput } from 'react-hook-form-input'
import { useForm } from 'react-hook-form'
import { routes } from 'Routes'
import useSnackbar from 'hooks/useSnackbar'
import * as services from 'service'

import { TextField, Box, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import styles from './styles'

import schema from './schema'
import { LoadingButton } from 'components'

const useStyles = makeStyles(styles)

const Form = ({ urlLoginWith }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const snackbar = useSnackbar()
  const history = useHistory()
  const { handleSubmit, errors, register, setValue } = useForm({
    defaultValues: {
      email: '',
    },
    validationSchema: schema,
  })

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await services.leaveningId.auth.forgotPassword(data)
      history.push(urlLoginWith || routes.login, {
        success:
          'Em poucos minutos será enviado para você um email para recuperação de senha',
      })
    } catch (error) {
      setLoading(false)
      if (error && error.response && error.response.status === 401) {
        snackbar.open({
          autoHideDuration: 15000,
          vertical: 'top',
          horizontal: 'center',
          message: 'Email ou senha inválidos',
          variant: 'error',
        })
        return
      }
      snackbar.open({
        autoHideDuration: 15000,
        vertical: 'top',
        horizontal: 'center',
        message: 'Ocorreu algum erro! Tente novamente!',
        variant: 'error',
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RHFInput
        as={
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            margin="normal"
            error={!!errors.email}
            helperText={errors?.email?.message}
            fullWidth
          />
        }
        register={register}
        setValue={setValue}
        name="email"
        mode="onChange"
      />
      <Box
        mt={2}
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <LoadingButton
          loading={loading}
          text="Recuperar"
          className={classes.buttonHover}
        />
      </Box>
    </form>
  )
}

export default Form
