import auth from './auth'
import users from './users'
import companies from './companies'
import addresses from './addresses'
import emails from './emails'

const leaveningID = {
  auth,
  users,
  companies,
  addresses,
  emails,
}

export default leaveningID
