import React from 'react'

import {
  Container,
  Box,
  Card,
  CardContent,
  CircularProgress,
} from '@material-ui/core'

import { ContentHeader, Page } from 'components'
import { CompanyForm } from './components'

import useFetch from 'hooks/useFetch'

import * as service from 'service'

const CompanyEdit = ({ match }) => {
  const { response, isLoading } = useFetch(service.leaveningId.companies.get, {
    token: match.params.token,
  })

  return (
    <Page title="Editar empresa">
      <Container>
        <ContentHeader
          menu="Empresas"
          title="Editar"
          subtitle="Editar empresa"
        />
        {!isLoading && response ? (
          <Box mt={3}>
            <Card>
              <CardContent>
                <CompanyForm companyData={response} />
              </CardContent>
            </Card>
          </Box>
        ) : (
          <Box
            id="loadBox"
            display="flex"
            width="100%"
            minHeight="700px"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Page>
  )
}

export default CompanyEdit
