import React from 'react'
import {
  Box,
  Typography,
  Breadcrumbs,
  makeStyles,
  Grid,
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import styles from './styles'

const useStyles = makeStyles(styles)

const ContentHeader = ({ menu, title, subtitle, children }) => {
  const classes = useStyles()

  return (
    <Box
      mb={2}
      display="flex"
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <Grid container spacing={3}>
        <Grid item xl={8} lg={7} md={6} sm={6} xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Typography
              className={classes.menu}
              variant="body1"
              color="textPrimary"
            >
              {menu}
            </Typography>
            <Typography
              className={classes.title}
              variant="body1"
              color="textPrimary"
            >
              {title}
            </Typography>
          </Breadcrumbs>
          <Typography className={classes.h3} variant="h3" color="textPrimary">
            {subtitle}
          </Typography>
        </Grid>
        <Grid item xl={4} lg={5} md={6} sm={6} xs={12}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {children}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContentHeader
