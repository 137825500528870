import React, { useState, useEffect } from 'react'

import * as service from 'service'
import AuthContext from 'contexts/AuthContext'
import {
  Home as HomeIcon,
  Dashboard as DashboardIcon,
  Settings as SettingIcon,
  Business as BusinessIcon,
  Lock as LockIcon,
} from '@material-ui/icons'

const AuthProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [menuItems, setMenuItems] = useState([])

  useEffect(() => {
    if (service.leaveningId.auth.isAuthenticated()) {
      loadDataWithAnimation()
    }
    // eslint-disable-next-line
  }, [])

  const loadData = async () => {
    const [responseUser] = await Promise.all([service.leaveningId.users.me()])
    setUser(responseUser.data.user)
    const menuItems = [
      {
        subheader: 'Principal',
        items: [
          {
            title: 'Home',
            icon: HomeIcon,
            href: '/',
          },
          {
            title: 'Acessos concedidos',
            icon: DashboardIcon,
            href: '/accesses-granted',
          },
          {
            title: 'Informações pessoais',
            icon: SettingIcon,
            href: '/account',
          },
          {
            title: 'Empresas',
            icon: BusinessIcon,
            href: '/company',
          },
          {
            title: 'Segurança',
            icon: LockIcon,
            href: '/security',
          },
        ],
      },
    ]

    setMenuItems(menuItems)
  }

  const loadDataWithAnimation = async () => {
    setIsLoading(true)
    setLoaded(false)

    try {
      await loadData()
    } finally {
      setLoaded(true)
      setIsLoading(false)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        loaded,
        isLoading,
        user,
        menuItems,
        loadData,
        loadDataWithAnimation,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
