import date from './date'
import dateFromNow from './dateFromNow'
import errorMessage from './errorMessage'
import hours from './hours'
import convertBoolToBinary from './convertBoolToBinary'
import convertBinaryToBool from './convertBinaryToBool'
import convertBoolToString from './convertBoolToString'
import cpf from './cpf'
import cnpj from './cnpj'
import responseErrorFormatter from './responseErrorFormatter'
import capitalize from './capitalize'
import phone from './phone'
import convertStringToInteger from './convertStringToInteger'
import cep from './cep'

const formatters = {
  convertBinaryToBool,
  convertBoolToBinary,
  convertStringToInteger,
  cep,
  date,
  dateFromNow,
  errorMessage,
  hours,
  convertBoolToString,
  cpf,
  cnpj,
  responseErrorFormatter,
  capitalize,
  phone,
}

export default formatters
