import dig from './dig'
import documentName from './documentName'
import upperText from './upperText'
import isEmpty from './isEmpty'
import getCurrentDevice from './getCurrentDevice'

const functions = {
  dig,
  documentName,
  upperText,
  isEmpty,
  getCurrentDevice,
}

export default functions
