import React, { useState } from 'react'

import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  makeStyles,
} from '@material-ui/core'

import { ContentHeader } from 'components'

import Page from 'components/Page'
import ChangePassword from './ChangePassword'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

const Security = () => {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState('password')

  const tabs = [{ value: 'password', label: 'Alterar senha' }]

  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  return (
    <Page className={classes.root} title="Alterar senha">
      <Container maxWidth="lg">
        <ContentHeader
          menu="Segurança"
          title="Senha"
          subtitle="Alterar senha"
        />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
            className={classes.tabs}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                onClick={(e) => handleTabsChange(e, tab.value)}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>{currentTab === 'password' && <ChangePassword />}</Box>
      </Container>
    </Page>
  )
}

export default Security
