import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Typography,
  TableContainer,
  Button,
  TablePagination,
  useMediaQuery,
  Box,
} from '@material-ui/core'

import { useTheme } from '@material-ui/styles'

import { MenuButton, ConfirmationDeleteModal } from 'components'

import * as service from 'service'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

import useSnackbar from 'hooks/useSnackbar'

import usePagination from 'hooks/usePagination'

const AddressRow = ({
  userAddress,
  setFormOpen = () => {},
  setAddress = () => {},
  handleMainAddress = () => {},
  setConfirmOpen = () => {},
}) => {
  const handleEdit = () => {
    setAddress(userAddress)
    setFormOpen(true)
  }

  const handleDestroy = () => {
    setAddress(userAddress)
    setConfirmOpen(true)
  }

  return (
    <TableRow>
      <TableCell>
        <Typography>{userAddress.address?.addressName || '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{userAddress.address?.city || '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{userAddress.address?.state || '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{userAddress.address?.country || '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{userAddress.mainAddress ? 'Sim' : 'Não'}</Typography>
      </TableCell>
      <TableCell align="right">
        <MenuButton>
          <Button onClick={handleEdit}>Editar</Button>
          {!userAddress.mainAddress && (
            <Button onClick={() => handleMainAddress(userAddress.id)}>
              Tornar principal
            </Button>
          )}
          <Button onClick={() => handleDestroy(userAddress.id)}>Remover</Button>
        </MenuButton>
      </TableCell>
    </TableRow>
  )
}

const AddressesTable = ({
  userAddresses,
  meta,
  isLoading,
  address,
  params,
  setAddress = () => {},
  setFormOpen = () => {},
  setParams = () => {},
}) => {
  const snackbar = useSnackbar()

  const {
    perPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    onRemoveLastFromPage,
  } = usePagination(5)

  const theme = useTheme()
  const [confirmOpen, setConfirmOpen] = useState(false)

  useEffect(() => {
    setParams({ perPage, page })
    //eslint-disable-next-line
  }, [perPage, page])

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const handleMainAddress = async (userAddressId) => {
    try {
      await service.leaveningId.addresses.changeMainAddress({ userAddressId })
      snackbar.open({
        message: 'Endereço principal alterado com sucesso',
        variant: 'success',
      })
      setParams({ perPage, page })
    } catch (err) {
      snackbar.open({
        message: 'Ocorreu um erro ao tornar esse endereço como principal',
        variant: 'error',
      })
    }
  }

  const handleDestroy = async (userAddressId) => {
    try {
      await service.leaveningId.addresses.destroy({ userAddressId })
      snackbar.open({
        message: 'Endereço removido com sucesso',
        variant: 'success',
      })
      setConfirmOpen(false)
      if (page > 1) {
        onRemoveLastFromPage(meta?.totalCount - 1)
      }
      setParams({
        perPage,
        page,
      })
    } catch (err) {
      snackbar.open({
        message: 'Ocorreu um erro ao remover esse endereço',
        variant: 'error',
      })
    }
  }

  return (
    <Box>
      <PerfectScrollbar>
        <TableContainer>
          <Table
            size="medium"
            emptyMessage="Nenhum endereço foi encontrado"
            noWrap
            isLoading={isLoading}
          >
            <TableHead>
              <TableRow>
                <TableCell width="25%">Rua</TableCell>
                <TableCell width="20%">Cidade</TableCell>
                <TableCell width="17%">Estado</TableCell>
                <TableCell width="16%">País</TableCell>
                <TableCell width="12%">Principal</TableCell>
                <TableCell width="10%" align="right">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userAddresses.map((userAddress) => (
                <AddressRow
                  userAddress={userAddress}
                  key={userAddress?.id}
                  setFormOpen={setFormOpen}
                  setAddress={setAddress}
                  handleMainAddress={handleMainAddress}
                  setConfirmOpen={setConfirmOpen}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={meta?.totalCount || 0}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={params?.page - 1}
        rowsPerPage={perPage}
        rowsPerPageOptions={[5, 10, 15, 25]}
        labelRowsPerPage={isDesktop ? 'Por página' : ''}
        nextIconButtonProps={{ size: 'small' }}
        backIconButtonProps={{ size: 'small' }}
      />
      <ConfirmationDeleteModal
        open={confirmOpen}
        setOpen={setConfirmOpen}
        suffixSentence="este endereço"
        title="Remover endereço"
        handleRemove={handleDestroy}
        objectToRemove={address?.id}
      />
    </Box>
  )
}

export default AddressesTable
