import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  makeStyles,
} from '@material-ui/core'

import helpers from 'helpers/'

import * as service from 'service'

import { useHistory } from 'react-router-dom'

import schema from './schema'

const useStyles = makeStyles(() => ({
  root: {},
}))
function Form({ className, onSubmitSuccess, match, ...rest }) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const search = qs.parse(location.search)

  const { handleSubmit, control, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  })

  async function onSubmit(values, { setStatus, setErrors }) {
    try {
      const { password, passwordConfirmation } = values

      const loginResponse = await service.leaveningId.users.resetPassword({
        password,
        passwordConfirmation,
        token: search.token,
        device: helpers.functions.getCurrentDevice(),
      })

      localStorage.setItem(
        '@leavening-id/user-token',
        loginResponse.data.auth_token,
      )

      const account = await service.leaveningId.users.me()

      localStorage.setItem(
        '@leavening-id/user',
        JSON.stringify(account.data.data),
      )

      history.push('/app')
    } catch (err) {
      setStatus({ success: false })

      if (err.response.status === 401) {
        setErrors({ submit: 'E-mail ou senha inválidos' })
      } else {
        setErrors({
          submit: 'Ocorreu um erro! Tente novamente mais tarde',
        })
      }
    }
  }

  return (
    <form
      noValidate
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit(onSubmit)}
      {...rest}
    >
      <Controller
        as={
          <TextField
            variant="outlined"
            error={!!errors.password}
            helperText={errors?.password?.message}
            label="Senha"
            fullWidth
          />
        }
        name="password"
        control={control}
        mode="onChange"
      />
      <Controller
        as={
          <TextField
            variant="outlined"
            error={!!errors.passwordConfirmation}
            helperText={errors?.passwordConfirmation?.message}
            label="Confirme a senha"
            fullWidth
          />
        }
        name="passwordConfirmation"
        control={control}
        mode="onChange"
      />
      <Box mt={2}>
        <Button
          color="secondary"
          size="large"
          type="submit"
          variant="contained"
        >
          Confirmar
        </Button>
        {errors.submit && (
          <Box mt={3}>
            <FormHelperText error>{errors.submit}</FormHelperText>
          </Box>
        )}
      </Box>
    </form>
  )
}

Form.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
}

Form.defaultProps = {
  onSubmitSuccess: () => {},
}

export default Form
