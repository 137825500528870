import { Check as CheckIcon, X as XIcon } from 'react-feather'

const statusIcon = (confirmed) => {
  if (confirmed) {
    return <CheckIcon color="white" />
  }

  if (!confirmed) {
    return <XIcon color="white" />
  }

  return null
}

export default statusIcon
