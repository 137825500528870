import api from 'service/api'

const leaveningIdAPI = api.create('leaveningId')
const leaveningIdAPINoTreat = api.create('leaveningId', false)

const get = async ({ emailId = '', ...params }) => {
  return await leaveningIdAPI.get(`/emails/${emailId}`, { params })
}

const edit = async ({ emailId, ...address }) => {
  return await leaveningIdAPI.put(`/emails/${emailId}`, {
    ...address,
  })
}

const create = async ({ ...params }) => {
  return await leaveningIdAPI.post('/emails/', params)
}

const destroy = async ({ emailId }) => {
  return await leaveningIdAPI.delete(`/emails/${emailId}`)
}

const confirm = async ({ confirmationToken }) => {
  return await leaveningIdAPINoTreat.get(`/emails/confirm/${confirmationToken}`)
}

const resendConfirmation = async ({ emailId }) => {
  return await leaveningIdAPI.get(`/emails/${emailId}/resend_confirmation`)
}

const emails = {
  get,
  edit,
  create,
  destroy,
  confirm,
  resendConfirmation,
}

export default emails
