import React from 'react'

import NewPasswordForm from './components/Form'
import Page from 'components/Page'
import routes from 'Routes'

import { Redirect } from 'react-router-dom'

import styles from './styles'
import {
  makeStyles,
  Container,
  Box,
  Card,
  CardContent,
  Avatar,
  Typography,
} from '@material-ui/core'

import LockIcon from '@material-ui/icons/Lock'

const useStyles = makeStyles(styles)

const NewPassword = ({ match }) => {
  const classes = useStyles()

  if (!match.params.reset_password_token) {
    return (
      <Redirect
        to={{
          pathname: routes.root,
          state: {
            error:
              'Você não pode acessar essa página para redefinir sua senha. Certifique-se de estar usando a URL correta.',
          },
        }}
      />
    )
  }

  const defaultValues = {
    password: '',
    passwordConfirm: '',
    resetPasswordToken: match.params.reset_password_token,
  }

  return (
    <Page className={classes.root} title="Nova senha">
      <Container maxWidth="md">
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <LockIcon fontSize="large" />
            </Avatar>
            <Typography variant="h4" color="textPrimary">
              Cadastre sua nova senha!
            </Typography>
            <Box mt={3}>
              <NewPasswordForm defaultValues={defaultValues} />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

export default NewPassword
