import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
} from '@material-ui/core'

import { EditUserForm, ViewUserData } from 'components'
import useAuth from 'hooks/useAuth'

const useStyles = makeStyles(() => ({
  root: {},
}))

function GeneralSettings({ className, ...rest }) {
  const classes = useStyles()
  const { user } = useAuth()
  const [editing, setEditing] = useState(false)

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Perfil" />
      <Divider />
      <CardContent>
        {editing ? (
          <EditUserForm mode="edit" setEdit={setEditing} userData={user} />
        ) : (
          <ViewUserData handleEdit={() => setEditing(true)} />
        )}
      </CardContent>
      <Divider />
    </Card>
  )
}

GeneralSettings.propTypes = {
  className: PropTypes.string,
}

export default GeneralSettings
