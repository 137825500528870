const styles = (theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  card: {
    overflow: 'visible',
  },
  bannerRight: {
    background: 'url(/static/register/how-it-works-networking.jpg) no-repeat ',
    backgroundSize: 'cover',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  bannerTextPrimary: {
    color: '#F0F3F4',
    fontSize: 14,
  },
  bannerTextSecundaryName: {
    color: '#F0F3F4',
    fontSize: 12,
  },
  bannerTextSecundaryOffice: {
    color: '#F0F3F4',
    fontSize: 11,
  },
  imageContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 100,
    width: 50,
    height: 50,
    paddingRight: 20,
  },
  bannerSecondaryRow: {
    marginBottom: 20,
    marginTop: 10,
  },
  cardContent: {
    paddingTop: 10,
  },
  lockIcon: {
    width: 70,
    height: 70,
    color: '#FFFFFF',
    background: theme.palette.primary.main,
    padding: 10,
    borderRadius: 5,
    marginTop: -50,
  },
  titleSignUp: {
    marginTop: 40,
  },
})

export default styles
