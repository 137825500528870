import * as yup from 'yup'

const schema = yup.object().shape({
  password: yup
    .string()
    .required()
    .min(8)
    .matches(
      /(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[@#$%^&.+=\\'?("|\[\]\)|!,/{}`\-\´<>;:~]).{8,}$/,
      'Sua nova senha deve possuir letras, números e pelo menos um caractere especial',
    ),
  password_confirmation: yup
    .string()
    .max(255)
    .required('Confirmação de senha obrigatória')
    .oneOf([yup.ref('password'), null], 'Senhas não coincidem.'),
})

export default schema
