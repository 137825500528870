import React from 'react'
import { Box, Typography } from '@material-ui/core'

const LabelView = ({ label, value }) => {
  return (
    <Box display="flex" flexDirection="column" m={2}>
      <Typography variant="h6">{label}</Typography>
      <Typography variant="body1">{value}</Typography>
    </Box>
  )
}

export default LabelView
