import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { Button, Box } from '@material-ui/core'
import * as service from 'service'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'
import { MenuButton, ConfirmationDeleteModal } from 'components'
import useSnackbar from 'hooks/useSnackbar'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { routes } from 'Routes'
import helpers from 'helpers'

const CompanyTable = ({
  ownerUserCompanies = [],
  setIsLoading = () => {},
  ...rest
}) => {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const snackbar = useSnackbar()

  // Começo da integração com componente de dialog de confirmação
  const [objectToRemove, setObjectToRemove] = useState({})

  const setOpenModal = (company) => {
    setOpen(true)
    setObjectToRemove(company)
  }

  const handleRemove = async (company) => {
    try {
      const response = await service.leaveningId.companies.destroy({
        token: company.token,
      })
      snackbar.open({
        message: response.data.message || 'Empresa removida com sucesso',
        variant: 'success',
      })
      setOpen(false)
      setIsLoading(true)
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.error?.message || 'Falha ao remover empresa',
        variant: 'error',
      })
    }
  }
  // fim da integração e utilização

  return (
    <>
      <PerfectScrollbar>
        <Table
          size="medium"
          emptyMessage="Nenhuma empresa foi encontrada"
          noWrap
          {...rest}
        >
          <TableHead size="medium">
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Nome fantasia</TableCell>
              <TableCell>CNPJ</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ownerUserCompanies?.map((ownerUserCompany) => (
              <TableRow key={ownerUserCompany.company.token}>
                <TableCell>{ownerUserCompany.company.name}</TableCell>
                <TableCell>{ownerUserCompany.company.tradeName}</TableCell>
                <TableCell>
                  {helpers.formatters.cnpj(ownerUserCompany.company.cnpj)}
                </TableCell>
                <TableCell align="right">
                  <Box display="flex" justifyContent="flex-end">
                    <MenuButton size="small">
                      <Button
                        onClick={() =>
                          history.push(
                            reverse(routes.app.company.edit, {
                              token: ownerUserCompany.company.token,
                            }),
                          )
                        }
                      >
                        Editar
                      </Button>
                      <Button
                        onClick={() => setOpenModal(ownerUserCompany.company)}
                      >
                        Remover
                      </Button>
                    </MenuButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <ConfirmationDeleteModal
        open={open}
        setOpen={setOpen}
        suffixSentence="esta empresa"
        title="Remover empresa"
        handleRemove={handleRemove}
        objectToRemove={objectToRemove}
        setIsLoading={setIsLoading}
      />
    </>
  )
}

export default CompanyTable
