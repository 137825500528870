import api from 'service/api'

const leaveningIdAPI = api.create('leaveningId')

const get = async ({ token = '', ...params }) => {
  return await leaveningIdAPI.get(`/companies/${token}`, { params })
}

const edit = async ({ token, company, ...params }) => {
  return await leaveningIdAPI.put(`/companies/${token}`, { company })
}

const create = async ({ params }) => {
  return await leaveningIdAPI.post('/companies/', params)
}

const destroy = async ({ token }) => {
  return await leaveningIdAPI.delete(`/companies/${token}`)
}
const companies = {
  get,
  edit,
  create,
  destroy,
}

export default companies
