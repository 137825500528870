import * as yup from 'yup'

export const schema = yup.object().shape({
  userAddressId: yup.string(),
  addressName: yup.string().required(),
  number: yup.string().required(),
  zipCode: yup
    .string()
    .required()
    .matches(/^(\d{5})-(\d{3}).*/, 'CEP inválido'),
  state: yup.string().required(),
  city: yup.string().required(),
  neighbor: yup.string(),
  complements: yup.string(),
  country: yup.string().required(),
  mainAddress: yup.boolean(),
})

export default schema
