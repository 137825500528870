import React from 'react'
import {
  Avatar,
  Box,
  Container,
  Card,
  CardContent,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core'
// import { Alert } from '@material-ui/lab';
import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons'
import Page from '../../components/Page'
import Form from './Form'

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

function Password({ match }) {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Entrar">
      <Container maxWidth="sm">
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <LockOutlinedIcon fontSize="large" />
            </Avatar>
            <Typography variant="h3" color="textPrimary">
              Crie sua senha
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Crie sua senha de acesso do OneID
            </Typography>
            {/* <Box mt={2}>
              <Alert
                severity="error"
              >
                <div>
                  <p>Pode ser utilizado Para verificação de campo</p>
                </div>
              </Alert>
            </Box> */}
            <Box mt={3}>
              <Form match={match} />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

export default Password
