import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core'

import { Add as AddIcon } from '@material-ui/icons'

import AddressesTable from './AddressesTable'
import AddressFormModal from './AddressFormModal'

import styles from './styles'

import * as service from 'service'

const useStyles = makeStyles(styles)

function AddressesMain({ ...rest }) {
  const [addresses, setAddresses] = useState()
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({})
  const [address, setAddress] = useState({})
  const [formOpen, setFormOpen] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    const loadAddresses = async () => {
      if (params?.perPage) {
        setLoading(true)
        const response = await service.leaveningId.addresses.get(params)
        setAddresses(response?.data)
        setLoading(false)
      }
    }

    loadAddresses()
  }, [params])

  const handleNew = () => {
    setAddress()
    setFormOpen(true)
  }

  return (
    <Card {...rest}>
      <CardHeader
        title={
          <Box
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="inherit">Endereços</Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={handleNew}
              startIcon={<AddIcon />}
            >
              Novo endereço
            </Button>
          </Box>
        }
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <AddressesTable
          userAddresses={addresses?.userAddresses || []}
          meta={addresses?.meta}
          isLoading={loading}
          address={address}
          params={params}
          setParams={setParams}
          setAddress={setAddress}
          setFormOpen={setFormOpen}
        />
      </CardContent>
      <Divider />
      <AddressFormModal
        open={formOpen}
        setOpen={setFormOpen}
        userAddress={address}
        loading={loading}
        params={params}
        setParams={setParams}
      />
    </Card>
  )
}

export default AddressesMain
