import React, { useEffect } from 'react'
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'

import {
  Avatar,
  Box,
  Container,
  Card,
  CardContent,
  Divider,
  Link,
  Typography,
  makeStyles,
} from '@material-ui/core'

import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons'

import Page from '../../components/Page'
import Form from './components/Form'

import useLoginWith from 'hooks/useLoginWith'
import useSnackbar from 'hooks/useSnackbar'

import styles from './styles'

const useStyles = makeStyles(styles)

const LoginWith = () => {
  const { loadData } = useLoginWith()
  const snackbar = useSnackbar()
  const history = useHistory()
  const urlLoginWith = new URL(window.location.href)
  const formattedUrlLoginWith = urlLoginWith.pathname + urlLoginWith.search

  const locationState = history.location.state

  const message = locationState
    ? locationState.error || locationState.success
    : ''

  useEffect(() => {
    loadData(formattedUrlLoginWith)
    if (locationState) {
      if (locationState.error) {
        snackbar.open({ message: message, variant: 'error' })
      } else {
        snackbar.open({ message: message, variant: 'success' })
      }
      history.replace(urlLoginWith)
    }
    //eslint-disable-next-line
  }, [])

  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }

  const classes = useStyles()
  let query = useQuery()

  localStorage.setItem('@leavening-id/socket-client', query.get('id'))
  localStorage.setItem(
    '@leavening-id/socket-client/company_token',
    query.get('company_token'),
  )

  return (
    <Page className={classes.root} title="Entrar">
      <Container maxWidth="xs">
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <LockOutlinedIcon fontSize="large" />
            </Avatar>
            <Typography variant="h3" color="textPrimary">
              Entrar com OneID
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Logue com sua conta!
            </Typography>
            <Box mt={3}>
              <Form />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
            <Typography variant="body2" color="textSecondary">
              Não tem uma conta?{' '}
              <Link component={RouterLink} to="/register" color="primary">
                Registrar-se
              </Link>
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

export default LoginWith
