import React from 'react'

import { makeStyles } from '@material-ui/core'

import SnackbarProvider from 'providers/SnackbarProvider'
import styles from './styles'

const useStyles = makeStyles(styles)

const Auth = ({ children }) => {
  const classes = useStyles()

  return (
    <SnackbarProvider>
      <div className={classes.root}>
        <main className={classes.wrapper}>{children}</main>
      </div>
    </SnackbarProvider>
  )
}

export default Auth
