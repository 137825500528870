import React from 'react'

import {
  Container,
  Box,
  Grid,
  CircularProgress,
  TablePagination,
  useMediaQuery,
  Paper,
} from '@material-ui/core'

import { ContentHeader, Page } from 'components'

import { useTheme } from '@material-ui/styles'
import { AppsTable } from './components'

import usePagination from 'hooks/usePagination'
import useFetch from 'hooks/useFetch'
import useAuth from 'hooks/useAuth'

import * as service from 'service'

const Dashboard = () => {
  const auth = useAuth()
  const {
    perPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination(10)

  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const { response, isLoading } = useFetch(
    service.leaveningId.users.companies,
    { perPage, page, userToken: auth.user.token },
    [page, perPage],
  )

  return (
    <Page title="Acessos concedidos">
      <Container>
        <ContentHeader
          menu="Acessos concedidos"
          title="Listagem"
          subtitle="Acessos concedidos"
        />
        {!isLoading && response ? (
          <>
            <Box display="flex" width="100%" mt={2}>
              <Grid container></Grid>
            </Box>
            <Paper>
              <AppsTable companies={response && response?.data?.companyUsers} />
              <TablePagination
                component="div"
                count={response.data.meta.totalCount}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Paper>
          </>
        ) : (
          <Box
            id="loadBox"
            display="flex"
            width="100%"
            minHeight="700px"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Page>
  )
}
export default Dashboard
