import React from 'react'

import { Grid, TextField, Box, DialogContent } from '@material-ui/core'

import { Controller, useForm } from 'react-hook-form'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import schema from './schema'

const EmailForm = ({
  email = null,
  edit,
  children,
  submitSuccess = () => {},
  setLoading = () => {},
}) => {
  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      emailId: edit ? email.id : '',
      email: edit ? email.email : '',
    },
  })

  const snackbar = useSnackbar()

  const onSubmit = async (values) => {
    const submitMethod = edit
      ? service.leaveningId.emails.edit
      : service.leaveningId.emails.create

    try {
      setLoading(true)
      await submitMethod({ ...values })
      snackbar.open({
        message: `E-mail ${
          edit ? 'editado' : 'criado'
        } com sucesso, um e-mail de confirmação foi enviado!`,
        variant: 'success',
      })
      submitSuccess()
    } catch {
      snackbar.open({
        message: `Erro ao ${edit ? 'editar' : 'criar'} e-mail`,
        variant: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Box my={1}>
          <Grid container spacing={2}>
            <Controller as={<></>} control={control} name="emailId" />
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="E-mail"
                    type="email"
                    color="primary"
                    variant="outlined"
                    error={!!errors.email}
                    helperText={errors && errors.email && errors.email.message}
                    fullWidth
                  />
                }
                control={control}
                name="email"
                mode="onBlur"
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      {children}
    </form>
  )
}

export default EmailForm
